// @ts-check
import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { PageContent } from "../../components/PageContent";
import { getUrlParameter } from "../../core/getUrlParameter";
import { CoreConstants } from "../../core/constants";
import { Typography } from "@mui/material";
import {
  Authenticator,
  PasswordField,
  TextField,
  useAuthenticator,
  View,
  Button,
  Link,
} from "@aws-amplify/ui-react";

const SignInHeader = () => {
  return (
    <>
      <Typography variant="h1" className="AuthContainer-header">
        Resource Center
      </Typography>
    </>
  );
};

const SignUpHeader = () => {
  return (
    <div className="AuthContainer-header">
      <Typography
        variant="h1"
        sx={{ marginBottom: 1 }}
        data-testid="auth-resource-center-text"
      >
        Resource Center
      </Typography>
      <Typography className="PageContent-headerSummary">
        Access all of the tools and documentation for deploying Anjuna's
        Confidential Cloud software.
      </Typography>
    </div>
  );
};

const ConfirmSignUpHeader = () => {
  return (
    <div>
      <Typography variant="h1" sx={{ marginBottom: 1 }}>
        Confirm your Anjuna Account
      </Typography>
      <Typography className="PageContent-headerSummary">
        We've sent an email containing your verification code. Enter the code
        below.
      </Typography>
    </div>
  );
};

export const AuthContainer = () => {
  const regToken = getUrlParameter(CoreConstants.AmazonRegistrationToken);
  const isAWS = !!regToken;
  const userEmailIn = getUrlParameter(CoreConstants.NewUserEmailUrlParam);
  const companyNameIn = getUrlParameter(
    CoreConstants.NewUserCompanyNameUrlParam
  );

  const hideSignUp = !isAWS && !(userEmailIn && companyNameIn);

  const initialAuthState =
    isAWS || (userEmailIn && companyNameIn) ? "signUp" : "signIn";

  const components = {
    SignIn: {
      Header: () => <SignInHeader />,
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <>
            <View textAlign="center" paddingBottom="15px">
              <Button
                fontWeight="normal"
                onClick={toResetPassword}
                variation="link"
              >
                Forgot your password?
              </Button>
            </View>
            <View textAlign="center" paddingBottom="25px">
              No account? Please
              <Link
                href="https://www.anjuna.io/contact-sales"
                isExternal={true}
                padding={`0 7px 0 7px`}
              >
                contact us
              </Link>
              for access
            </View>
          </>
        );
      },
    },
    SignUp: {
      Header: () => <SignUpHeader />,
      FormFields: () => (
        <>
          {userEmailIn ? (
            <>
              <TextField
                label=""
                required
                name="email"
                autoComplete="email"
                placeholder="Your company email"
                type="email"
                value={userEmailIn}
                disabled
              />
              <input type="hidden" name="email" value={userEmailIn} />
            </>
          ) : (
            <TextField
              label=""
              required
              name="email"
              autoComplete="email"
              placeholder="Your company email"
              type="email"
            />
          )}
          <TextField
            label=""
            required
            autoComplete="name"
            name="name"
            placeholder="Your full name"
          />
          {companyNameIn ? (
            <>
              <TextField
                label=""
                required
                placeholder="Company name"
                name="family_name"
                value={companyNameIn}
                disabled
              />
              <input type="hidden" name="family_name" value={companyNameIn} />
            </>
          ) : (
            <TextField
              label=""
              required
              placeholder="Company name"
              name="family_name"
            />
          )}
          <PasswordField
            label=""
            autoComplete="new-password"
            placeholder="Enter a password (min 8 characters)"
            name="password"
            id="password"
            required
          />
          <PasswordField
            label=""
            name="confirm_password"
            placeholder="Confirm password"
            autoComplete="confirm-password"
            required
          />
        </>
      ),
    },
    ConfirmSignUp: {
      Header: () => <ConfirmSignUpHeader />,
    },
  };

  return (
    <PageContent application includeHero noPad>
      <Authenticator
        components={components}
        initialState={initialAuthState}
        hideSignUp={hideSignUp}
      >
        {({ signOut, user }) => <main />}
      </Authenticator>
    </PageContent>
  );
};
